import React from "react";
import { useSelector } from "react-redux";
import { Container, Dropdown, Grid, Icon, Menu } from "semantic-ui-react";
import SignedInMenu from "./SignedInMenu";
import SignedOutMenu from "./SignedOutMenu";
import { Link, NavLink, useParams } from "react-router-dom";
import { isEmpty, isLoaded, useFirestoreConnect } from "react-redux-firebase";
import { updateUserProfileData } from "../auth/authActions";

export default function NavBar({ setVisible, visible }) {
  const { authenticated, currentUser } = useSelector((state) => state.auth);
  const { workspaceId, spaceId } = useParams();

  const selectedWorkspace = useSelector(
    (state) =>
      workspaceId && state.firestore.ordered[`workspaces/${workspaceId}`]
  );
  const selectedSpace = useSelector(
    (state) => spaceId && state.firestore.ordered[`spaces/${spaceId}`]
  );

  const selectedWorkspaceQuery = {
    collection: "workspaces",
    doc: workspaceId,
    storeAs: `workspaces/${workspaceId}`,
  };
  const selectedSpaceQuery = {
    collection: "spaces",
    doc: spaceId,
    storeAs: `spaces/${spaceId}`,
  };

  useFirestoreConnect(() => workspaceId && [selectedWorkspaceQuery]);
  useFirestoreConnect(() => spaceId && [selectedSpaceQuery]);
  async function handleMakeDefault() {
    const userUpdate = {
      uid: currentUser.uid,
      currentSpace: spaceId,
      currentWorkspace: workspaceId,
    };
    console.log(userUpdate);
    try {
      await updateUserProfileData(userUpdate);
    } catch (error) {
      console.log(error.message);
      // toast.error(error.message);
    }
  }

  return (
    <>
      <Menu
        className='navbar'
        inverted
        borderless
        fixed='top'
        //       style={{
        //         backgroundImage: `linear-gradient(
        //   135deg,
        //   #1F2024 0%,

        //   #28292B 75%
        // )`,
        //       }}
      >
        <Container>
          <Menu.Item
            style={{ width: "40px" }}
            icon={visible ? "times" : "bars"}
            onClick={() => setVisible(!visible)}
          />

          <Menu.Item as={NavLink} to='/home' icon='home' />
          {isLoaded(selectedWorkspace) && !isEmpty(selectedWorkspace) ? (
            <Menu.Item position='left'>
              <Dropdown pointing='top right' text={selectedWorkspace[0].title}>
                <Dropdown.Menu>
                  <Dropdown.Item
                    onClick={handleMakeDefault}
                    text='Make Default'
                    icon='star'
                  />
                  <Dropdown.Divider />
                  <Dropdown.Item
                    as={Link}
                    to={`/workspaces/${workspaceId}/spaces`}
                  >
                    <Icon name='building outline' />
                    Projects
                  </Dropdown.Item>
                  <Dropdown.Item
                    as={Link}
                    to={`/workspaces/${workspaceId}/users`}
                  >
                    <Icon name='users' />
                    Users
                  </Dropdown.Item>
                  <Dropdown.Item
                    as={Link}
                    to={`/workspaces/${workspaceId}/finance`}
                  >
                    <Icon name='money' />
                    Finance
                  </Dropdown.Item>
                  <Dropdown.Item>
                    <Icon name='list' />

                    <Dropdown text='Library'>
                      <Dropdown.Menu>
                        <Dropdown.Item
                          as={Link}
                          to={`/workspaces/${workspaceId}/supplier`}
                        >
                          Suppliers
                        </Dropdown.Item>
                        <Dropdown.Item>Materials</Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Menu.Item>
          ) : (
            <></>
          )}
          {isLoaded(selectedSpace) && !isEmpty(selectedSpace) ? (
            <Menu.Item
              position='left'
              content={selectedSpace[0].title}
              as={Link}
              to={`/workspaces/${workspaceId}/spaces/${spaceId}/`}
            />
          ) : (
            <></>
          )}

          <Menu.Item>
            <Grid>
              <Grid.Column only='large screen'></Grid.Column>
            </Grid>
          </Menu.Item>
          <Menu.Item content='Arjuan' position='left' />
          {/* <Menu.Item as={NavLink} to='/workspaces' name='workspaces' />
          <Menu.Item as={NavLink} to='/comms' name='communications' /> */}
          {/* <Menu.Item as={NavLink} to='/sandbox' name='Sandbox' /> */}

          {authenticated ? <SignedInMenu /> : <SignedOutMenu />}
        </Container>
      </Menu>
    </>
  );
}
