import { Form, Formik } from "formik";
import React, { useState } from "react";
import { Button, Header, Label, Progress, Segment } from "semantic-ui-react";
import MyTextInput from "../../app/common/form/MyTextInput";
import * as Yup from "yup";
import MyTextArea from "../../app/common/form/MyTextArea";
import MySelectInput from "../../app/common/form/MySelectInput";
import { categoryData } from "../../app/api/categoryOptions";
import MyDateInput from "../../app/common/form/MyDateInput";
import {
  addSpace,
  storageDeleteFolder,
  updateSpace,
} from "../../app/firestore/firestoreService";
import { useDispatch } from "react-redux";
import { closeModal } from "../../app/common/modals/modalReducer";
import MyGeoLocInput from "../../app/common/form/MyGeoLocInput";
import MyFileInput from "../../app/common/form/MyFileInput";
import { uploadToFirebaseStorage } from "../../app/firestore/firebaseService";
import MyDigitInput from "../../app/common/form/MyDigitInput";

const initialValues = {
  title: "",
  description: "",
  category: "",
  date: "",
  logo: "",
  geoLocation: { latitude: 0, longitude: 0 },
  file: undefined,
  BUA: 0,
  budget: 0,
  designStartDate: "",
  designEndDate: "",
  constructionStartDate: "",
  constructionEndDate: "",
};

const validationSchema = Yup.object({
  title: Yup.string().required("you must provide a title"),
  description: Yup.string().required("you must provide a description"),
  category: Yup.string().required("you must select a category"),
  date: Yup.string().required("you must select a date"),

  file: Yup.mixed().notRequired(),
  geoLocation: Yup.object({
    // latitude: Yup.number().min(2).default(3).nullable(true),
    // longitude: Yup.number().min(2).default(4).nullable(true),
    latitude: Yup.number().min(-90).max(90),
    longitude: Yup.number().min(-180).max(180),
  }),
  BUA: Yup.number().notRequired(),
  budget: Yup.number().notRequired(),
  designStartDate: Yup.string().notRequired().nullable(),
  designEndDate: Yup.string().notRequired().nullable(),
  constructionStartDate: Yup.string().notRequired().nullable(),
  constructionEndDate: Yup.string().notRequired().nullable(),
});

export default function SpaceForm({ workspaceId, selectedSpace }) {
  const dispatch = useDispatch();
  const [progress, setProgress] = useState(0);

  return (
    <Segment clearing basic>
      {selectedSpace ? (
        <>
          <Header sub content='Edit Project' />
          {progress > 0 && (
            <Progress percent={progress} size='tiny' color='orange' />
          )}
        </>
      ) : (
        <Header sub content='Create Project' />
      )}

      <Formik
        enableReinitialize
        initialValues={selectedSpace ? selectedSpace[0] : initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values, { setSubmitting, setErrors }) => {
          const { file, ...valueWithNoFile } = values;

          try {
            if (selectedSpace) {
              await dispatch(
                updateSpace(valueWithNoFile, { spaceId: selectedSpace[0].id })
              );
              if (values.file) {
                const folderPath = `workspaces/${workspaceId}/spaces/${selectedSpace[0].id}`;
                await dispatch(storageDeleteFolder(folderPath));
                await dispatch(
                  uploadToFirebaseStorage(
                    values.file,
                    values.file.name,
                    selectedSpace[0].id,
                    null,
                    "spaces",
                    "spaces",
                    workspaceId,
                    false,
                    setProgress
                  )
                );
              }
              dispatch(closeModal());
              setSubmitting(false);
            } else {
              await dispatch(addSpace(valueWithNoFile, workspaceId));
              dispatch(closeModal());
              setSubmitting(false);
            }
          } catch (error) {
            setErrors({ submit: error.message });
            setSubmitting(false);
          }
        }}
      >
        {({ isSubmitting, isValid, dirty, errors }) => (
          <Form className='ui form'>
            <MyTextInput name='title' placeholder='Project name' />
            <MyTextArea
              name='description'
              placeholder='Description...'
              rows={3}
            />
            <MySelectInput
              name='category'
              placeholder='stage'
              options={categoryData}
            />

            <MyDateInput
              name='date'
              placeholderText='enter date'
              dateFormat='MMMM d, yyyy'
            />
            {selectedSpace && (
              <>
                <MyDateInput
                  name='designStartDate'
                  placeholderText='enter designStartDate'
                  dateFormat='MMMM d, yyyy'
                />
                <MyDateInput
                  name='designEndDate'
                  placeholderText='enter designEndDate'
                  dateFormat='MMMM d, yyyy'
                />
                <MyDateInput
                  name='constructionStartDate'
                  placeholderText='enter constructionStartDate'
                  dateFormat='MMMM d, yyyy'
                />
                <MyDateInput
                  name='constructionEndDate'
                  placeholderText='enter constructionEndDate'
                  dateFormat='MMMM d, yyyy'
                />
                <MyDigitInput name='BUA' placeholder='enter built up area' />
                <MyDigitInput name='budget' placeholder='enter budget' />
                <MyFileInput
                  name='file'
                  id='file'
                  type='file'
                  accept='image/*'
                  placeholder='browse file'
                />
              </>
            )}

            {selectedSpace && (
              <MyGeoLocInput
                name='geoLocation'
                placeholder='enter latitude'
                testk={"hello"}
              />
            )}

            {errors.submit && (
              <Label
                basic
                color='red'
                style={{ marginBottom: 10 }}
                content={errors.submit}
              />
            )}
            <Button
              style={{ backgroundColor: "#ffa600", color: "white" }}
              loading={isSubmitting}
              disabled={!isValid || !dirty || isSubmitting}
              type='submit'
              floated='right'
              positive
              content={selectedSpace ? "Edit" : "Create"}
            />
          </Form>
        )}
      </Formik>
    </Segment>
  );
}
