import { ADD_COMM } from "../../features/comm/commConstants";
import { ADD_SKETCH2D } from "../../features/design/sketch2d/sketch2dConstants";
import { ADD_SPACE, UPDATE_SPACE } from "../../features/space/spaceConstants";
import { ADD_USER } from "../../features/user/userConstants";
import { ADD_WORKSPACE } from "../../features/workspace/workspaceConstants";

function getRandomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min) + min); //The maximum is exclusive and the minimum is inclusive
}

export function addWorkspace(value) {
  return (dispatch, getState, getFirebase) => {
    const firebase = getFirebase();
    const firestore = firebase.firestore();
    const user = firebase.auth().currentUser;

    const newValue = {
      ...value,
      logo: `https://picsum.photos/id/${getRandomInt(100, 200)}/100?grayscale`,
      // createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      createdBy: firebase.auth().currentUser.uid,
      membersIds: firebase.firestore.FieldValue.arrayUnion(user.uid),
      members: firebase.firestore.FieldValue.arrayUnion({
        id: user.uid,
        displayName: user.displayName,
        photoURL: user.photoURL,
      }),
      isCancelled: false,
    };

    return firestore
      .collection("workspaces")
      .add(newValue)
      .then(() => {
        dispatch({ type: ADD_WORKSPACE, payload: newValue });
      })
      .catch((error) => {
        throw error;
      });
  };
}

export function deleteWorkspace(valueId) {
  return (dispatch, getState, getFirebase) => {
    const firebase = getFirebase();
    const firestore = firebase.firestore();

    firestore.collection("workspaces").doc(valueId).delete();
  };
}

export function cancelWorkspace(valueId) {
  return (dispatch, getState, getFirebase) => {
    const firebase = getFirebase();
    const firestore = firebase.firestore();

    firestore
      .collection("workspaces")
      .doc(valueId)
      .update({ isCancelled: true });
  };
}

export function addWorkspaceUser(value, workspaceId) {
  return async (dispatch, getState, getFirebase) => {
    const firebase = getFirebase();
    const firestore = firebase.firestore();
    const functions = firebase.functions();

    const getUserProfile = functions.httpsCallable("getUserProfileByEmail");
    await getUserProfile({ email: value, workspaceId: workspaceId }).then(
      (result) => {
        console.log("result: ", value, result);
      }
    );

    // return firestore
    //   .collection("workspaces")
    //   .doc(workspaceId)
    //   .update({ membersIds: firebase.firestore.FieldValue.arrayUnion(value) })
    //   .then(() => {
    //     dispatch({ type: ADD_USER, payload: value });
    //   })
    //   .catch((error) => {
    //     throw error;
    //   });
  };
}

export function storageDeleteFolder(path) {
  return async (dispatch, getState, getFirebase) => {
    const firebase = getFirebase();
    const functions = firebase.functions();

    const deleteBucket = functions.httpsCallable("deleteBucketPath");
    await deleteBucket({ path: path }).then((result) => {
      console.log("result: ", result);
    });
  };
}

export function addSpace(value, workspaceId) {
  return (dispatch, getState, getFirebase) => {
    const firebase = getFirebase();
    const firestore = firebase.firestore();

    const newValue = {
      ...value,
      logo: `https://picsum.photos/id/${getRandomInt(100, 200)}/100?grayscale`,
      // createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      createdBy: firebase.auth().currentUser.uid,
      isCancelled: false,
      workspaceId: workspaceId,
    };

    return firestore
      .collection("spaces")
      .add(newValue)
      .then(() => {
        dispatch({ type: ADD_SPACE, payload: newValue });
      })
      .catch((error) => {
        throw error;
      });
  };
}

export function updateSpace(value, { spaceId }) {
  return (dispatch, getState, getFirebase) => {
    console.log(spaceId, value);
    const firebase = getFirebase();
    const firestore = firebase.firestore();

    const newValue = {
      ...value,
      geoLocation: new firebase.firestore.GeoPoint(
        parseFloat(value.geoLocation.latitude),
        parseFloat(value.geoLocation.longitude)
      ),
    };

    return firestore
      .collection("spaces")
      .doc(spaceId)
      .update(newValue)
      .then(() => {
        dispatch({ type: UPDATE_SPACE, payload: newValue });
        return spaceId;
      })
      .catch((error) => {
        throw error;
      });
  };
}

export function addComm(value) {
  return (dispatch, getState, getFirebase) => {
    const firebase = getFirebase();
    const firestore = firebase.firestore();

    const newValue = {
      ...value,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      createdBy: firebase.auth().currentUser.uid,
      isCancelled: false,
      // notesTrigram: trigram(value.notes.toLowerCase()),
    };

    return firestore
      .collection("comms")
      .add(newValue)
      .then(() => {
        dispatch({ type: ADD_COMM, payload: newValue });
      })
      .catch((error) => {
        throw error;
      });
  };
}

export function cancelComm(valueId) {
  return (dispatch, getState, getFirebase) => {
    const firebase = getFirebase();
    const firestore = firebase.firestore();

    firestore.collection("comms").doc(valueId).update({ isCancelled: true });
  };
}

export function addSketch2d(value, spaceId, workspaceId) {
  return (dispatch, getState, getFirebase) => {
    const firebase = getFirebase();
    const firestore = firebase.firestore();

    const newValue = {
      ...value,
      imageUrl: "",
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      createdBy: firebase.auth().currentUser.uid,
      isCancelled: false,
      spaceId: spaceId,
      workspaceId: workspaceId,
    };

    return firestore
      .collection("sketch2ds")
      .add(newValue)
      .then((item) => {
        dispatch({ type: ADD_SKETCH2D, payload: newValue });
        return item.id;
        // console.log(item.id);
      })
      .catch((error) => {
        throw error;
      });
  };
}

export function cancelSketch2d(valueId) {
  return (dispatch, getState, getFirebase) => {
    const firebase = getFirebase();
    const firestore = firebase.firestore();

    firestore
      .collection("sketch2ds")
      .doc(valueId)
      .update({ isCancelled: true });
  };
}

export function addCard(
  value,
  collection,
  spaceId,
  supplierId,
  workspaceId,
  sectionId
) {
  return (dispatch, getState, getFirebase) => {
    const firebase = getFirebase();
    const firestore = firebase.firestore();

    const newValue = {
      ...value,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      createdBy: firebase.auth().currentUser.uid,
      isCancelled: false,
      ...(spaceId && { spaceId: spaceId }),
      ...(supplierId && { supplierId: supplierId }),
      workspaceId: workspaceId,
      sectionId: sectionId ? sectionId : null,
    };

    return firestore
      .collection(collection)
      .add(newValue)
      .then((item) => {
        return item.id;
      })
      .catch((error) => {
        throw error;
      });
  };
}

export function updateCard(value, collection, docId) {
  return (dispatch, getState, getFirebase) => {
    const firebase = getFirebase();
    const firestore = firebase.firestore();

    return firestore
      .collection(collection)
      .doc(docId)
      .update(value)
      .then(() => {
        return docId;
      })
      .catch((error) => {
        throw error;
      });
  };
}

export function cancelDocById(docId, collection, boolean) {
  return (dispatch, getState, getFirebase) => {
    const firebase = getFirebase();
    const firestore = firebase.firestore();

    firestore
      .collection(collection)
      .doc(docId)
      .update({ isCancelled: boolean });
  };
}

export function commmitFile(value, collection, docId, spaceId, workspaceId) {
  return (dispatch, getState, getFirebase) => {
    const firebase = getFirebase();
    const firestore = firebase.firestore();

    const newValue = {
      ...value,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      createdBy: firebase.auth().currentUser.uid,
      spaceId: spaceId,
      workspaceId: workspaceId,
      docId: docId,
    };

    return firestore
      .collection("commits")
      .add(newValue)
      .then((item) => {
        return item.id;
      })
      .catch((error) => {
        throw error;
      });
  };
}

export function deleteDocFromFirestore(doc, collection) {
  return (dispatch, getState, getFirebase) => {
    const firebase = getFirebase();
    const firestore = firebase.firestore();
    console.log("isCanceled: ", doc.isCancelled);

    const docRef = firestore.collection(collection).doc(doc.id);
    if (doc.isCancelled) {
      docRef.delete();
    } else {
      console.log("can not be deleted, cancel first...");
    }
  };
}

export function updateDocImageUrl(
  collection,
  docId,
  imageUrl,
  imageResizedSmallUrl,
  imageResizedLargeUrl,
  imageFilename
) {
  return (dispatch, getState, getFirebase) => {
    const firebase = getFirebase();
    const firestore = firebase.firestore();

    return firestore
      .collection(collection)
      .doc(docId)
      .update({
        imageUrl: imageUrl,
        imageResizedSmallUrl: imageResizedSmallUrl,
        imageResizedLargeUrl: imageResizedLargeUrl,
        imageFilename: imageFilename,
      })
      .then(() => {
        console.log("URL updated");
      })
      .catch((error) => {
        throw error;
      });
  };
}
export function updateDocFileUrl(
  collection,
  docId,
  fileUrl,
  field,
  parent = false
) {
  return (dispatch, getState, getFirebase) => {
    const firebase = getFirebase();
    const firestore = firebase.firestore();
    let updateObject = {};

    const setUpdateObject = (field, parent) => {
      switch (field) {
        case "pdf":
          if (parent) {
            updateObject = { pdfUrl: fileUrl };
          } else {
            updateObject = { fileUrl: fileUrl, fileType: "pdf" };
          }
          break;
        case "dwg":
          if (parent) {
            updateObject = { dwgUrl: fileUrl };
          } else {
            updateObject = { fileUrl: fileUrl, fileType: "dwg" };
          }
          break;
        case "afpub":
          if (parent) {
            updateObject = { afpubUrl: fileUrl };
          } else {
            updateObject = { fileUrl: fileUrl, fileType: "afpub" };
          }
          break;
        case "skp":
          if (parent) {
            updateObject = { skpUrl: fileUrl };
          } else {
            updateObject = { fileUrl: fileUrl, fileType: "skp" };
          }
          break;

        default:
          updateObject = { fileUrl: fileUrl };
      }
      return updateObject;
    };

    return firestore
      .collection(collection)
      .doc(docId)
      .update(setUpdateObject(field, parent))
      .then(() => {
        console.log("URL updated");
      })
      .catch((error) => {
        throw error;
      });
  };
}

export function addComment(value, docId) {
  return (dispatch, getState, getFirebase) => {
    const firebase = getFirebase();
    const firestore = firebase.firestore();

    const newValue = {
      ...value,
      imageUrl: "",
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      createdBy: firebase.auth().currentUser.uid,
      displayName: firebase.auth().currentUser.displayName,
      photoURL: firebase.auth().currentUser.photoURL,
      docId: docId,
    };

    return firestore
      .collection("comments")
      .add(newValue)
      .then((item) => {
        // dispatch({ type: ADD_SKETCH2D, payload: newValue });
        return item.id;
        // console.log(item.id);
      })
      .catch((error) => {
        throw error;
      });
  };
}
