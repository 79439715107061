const fbConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: "pipeline-zr1.firebaseapp.com",
  projectId: "pipeline-zr1",
  storageBucket: "pipeline-zr1.appspot.com",
  messagingSenderId: "436598843997",
  appId: "1:436598843997:web:c7c02f5177a9d0f0cefbb4",
  measurementId: "G-PH1CX1QDWD",
}; // your firebase config

export default fbConfig;
