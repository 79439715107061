import React from "react";
import { Header } from "semantic-ui-react";
import UserListItem from "./UserListItem";

export default function UserList({ users, adminId }) {
  if (users) {
    return (
      <>
        {users.map((user) => (
          <UserListItem user={user} key={user.id} adminId={adminId} />
        ))}
      </>
    );
  } else {
    <>
      <Header content='loading...' />
    </>;
  }
}
