import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty, isLoaded, useFirestoreConnect } from "react-redux-firebase";
import { Card, Comment } from "semantic-ui-react";
import CommentListItem from "./CommentListItem";

export default function CommentList({ docId }) {
  const dispatch = useDispatch();
  let comments = [];
  const auth = useSelector((state) => state.firebase.auth);
  let commentsQuery;
  if (isLoaded(auth) && !isEmpty(auth)) {
    commentsQuery = {
      collection: "comments",
      orderBy: ["createdAt", "desc"],
      storeAs: `comments/${docId}`,
      where: [["docId", "==", docId]],
    };
  }
  useFirestoreConnect(() => [commentsQuery]);
  comments = useSelector(
    (state) => state.firestore.ordered[`comments/${docId}`]
  );
  return (
    <>
      {isLoaded(comments) && !isEmpty(comments) ? (
        <Comment.Group style={{ maxWidth: "100%" }}>
          {comments.map((comment) => (
            <CommentListItem comment={comment} key={comment.id} />
          ))}
        </Comment.Group>
      ) : (
        <>'loading...'</>
      )}
    </>
  );
}
