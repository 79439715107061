import React from "react";
import { Header, Input, Menu } from "semantic-ui-react";
import Calendar from "react-calendar";

export default function CommFilters({
  setPointer,
  setNoteSearch,
  setDateSearch,
  setQueryLimit,
  daysAgo,
}) {
  return (
    <>
      <Menu vertical size='large' style={{ width: "100%" }}>
        <Header content='Filters' attached color='teal' icon='filter' />

        <Menu.Item>
          <Input
            placeholder='Search notes...'
            onChange={(value) => {
              setQueryLimit(20);
              setNoteSearch(value.target.value);
            }}
          />
        </Menu.Item>
        <Menu.Item>
          <Header
            content='Search by date'
            size='small'
            color='teal'
            icon='calendar'
          />
          <Calendar
            value={daysAgo}
            onChange={(date) => {
              setDateSearch(date);
              setPointer();
              setQueryLimit(5);
            }}
          />
        </Menu.Item>
      </Menu>
    </>
  );
}
