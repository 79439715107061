import React, { createRef, useState } from "react";
import {
  Button,
  Container,
  Grid,
  Header,
  Ref,
  Sticky,
} from "semantic-ui-react";

import CommForm from "./CommForm";
import {
  useFirestoreConnect,
  useFirebase,
  isLoaded,
  isEmpty,
} from "react-redux-firebase";
import { useSelector } from "react-redux";
import CommListItemPlaceholder from "./CommListItemPlaceholder";
import CommList from "./CommList";
import CommFilters from "./CommFilters";
import { filterPlainArray, getDaysAgo } from "../../app/common/util/util";

export default function CommDashboard() {
  const daysAgo = getDaysAgo(4);
  const [noteSearch, setNoteSearch] = useState("");
  const [dateSearch, setDateSearch] = useState(daysAgo);
  const [pointer, setPointer] = useState();
  const [page, setPage] = useState(1);
  const [backwardPointer, setBackwardPointer] = useState();
  const [queryLimit, setQueryLimit] = useState(5);

  const contextRef = createRef();
  const firebase = useFirebase();
  const auth = useSelector((state) => state.firebase.auth);

  let commsQuery;
  if (isLoaded(auth) && !isEmpty(auth)) {
    commsQuery = {
      collection: "comms",
      orderBy: [
        ["createdAt", "desc"],
        // ["notes", "desc"],
      ],

      where: [
        ["createdBy", "==", firebase.auth().currentUser.uid],
        ["createdAt", ">=", dateSearch],
        // ["notes", ">=", noteSearch],
        // ["notes", "<=", noteSearch + "\uf8ff"],
        ["isCancelled", "==", false],
      ],
      limit: queryLimit,
      startAfter: pointer?.createdAt,
      endBefore: backwardPointer?.createdAt,
    };
  } else {
    commsQuery = {
      collection: "comms",
      orderBy: ["createdAt", "desc"],
    };
  }

  useFirestoreConnect(() => [commsQuery]);

  const profileQuery = {
    collection: "users",
  };

  // useEffect(() => {
  //   console.log("pointer changed....");
  // }, [pointer]);

  useFirestoreConnect(() => [profileQuery]);

  const { comms } = useSelector((state) => state.firestore.ordered);

  let commsFiltered;

  if (isLoaded(comms) && !isEmpty(comms)) {
    commsFiltered = filterPlainArray(comms, {
      notes: noteSearch,
      // createdAt: dateSearch,
    });
  }

  return (
    <>
      <Container
        style={{
          marginTop: 20,
          marginBottom: 200,
          // overflow: "hidden",
          // display: "table",
          // clear: "both",
        }}
      >
        <Ref innerRef={contextRef}>
          <Grid stackable style={{ minHeight: "100vh" }}>
            <Grid.Column width='10'>
              {isLoaded(comms) && !isEmpty(comms) ? (
                <>
                  <CommList comms={commsFiltered} />
                  {comms.length < queryLimit ? (
                    <>
                      <Header as='h5' disabled content='No more Data ...' />
                      <Button
                        onClick={() => {
                          // console.log(lastDoc);
                          setPointer(backwardPointer);
                          setPage(page - 1);
                        }}
                        content='Go to First'
                      />
                    </>
                  ) : (
                    <>
                      <Button
                        onClick={() => {
                          // if (page > 1) {
                          //   setBackwardPointer(comms[0]);
                          // }

                          // console.log(lastDoc);
                          setPointer(comms[comms.length - 1]);
                          setPage(page + 1);
                        }}
                        content='Next'
                      />
                      {page > 1 && (
                        <Button
                          onClick={() => {
                            // console.log(lastDoc);
                            setPointer(backwardPointer);
                            setPage(page - 1);
                          }}
                          content='Previous'
                        />
                      )}
                    </>
                  )}
                </>
              ) : (
                <>
                  <CommListItemPlaceholder />
                  <CommListItemPlaceholder />
                </>
              )}
            </Grid.Column>
            <Grid.Column width='6'>
              <Sticky offset={80} context={contextRef}>
                <CommForm setPointer={setPointer} />
                <CommFilters
                  daysAgo={daysAgo}
                  setQueryLimit={setQueryLimit}
                  setNoteSearch={setNoteSearch}
                  setDateSearch={setDateSearch}
                  setPointer={setPointer}
                />
              </Sticky>
            </Grid.Column>
          </Grid>
        </Ref>
      </Container>
    </>
  );
}
