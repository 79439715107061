import React from "react";
import { Grid } from "semantic-ui-react";

import { useMediaQuery } from "react-responsive";

export default function SectionList({ children, sections }) {
  const ismobile = useMediaQuery({ query: "(max-width: 768px)" });
  const childrenWithProps = React.Children.map(children, (child) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, { sections });
    }
    return child;
  });

  return (
    <>
      <Grid
        // columns={3}
        divided
        style={{
          //   backgroundColor: "gray",
          position: "fixed",
          height: "100%",
          overflowX: "scroll",
          scrollSnapType: "x mandatory",
          display: "grid",
          gridColumnGap: "1rem",
          margin: "0 0% 0 1%",
          left: "0",
          right: "0",

          gridTemplateColumns: `repeat(${sections.length}, ${
            ismobile ? "100%" : sections.length > 1 ? "25rem" : "100%"
          })`,
        }}
      >
        <>{childrenWithProps}</>
      </Grid>
    </>
  );
}
