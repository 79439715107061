import React from "react";
import ModalWrapper from "../../app/common/modals/ModalWrapper";
import CardForm from "./CardForm";

export default function CardFormModal({
  workspaceId,
  spaceId,
  supplierId,
  sectionId,
  selectedDoc,
  collection,
}) {
  return (
    <ModalWrapper size='small'>
      <CardForm
        workspaceId={workspaceId}
        spaceId={spaceId || null}
        supplierId={supplierId}
        sectionId={sectionId}
        selectedDoc={selectedDoc}
        collection={collection}
      />
    </ModalWrapper>
  );
}
