import React from "react";
import { Menu, Accordion, Icon, Segment } from "semantic-ui-react";
import { NavLink, Link, useRouteMatch, Route } from "react-router-dom";

const baseList = (
  <div>
    <Menu.Item as={NavLink} to='/workspaces'>
      <Icon name='building outline' />
      Projects
    </Menu.Item>
    <Menu.Item as='a'>
      <Icon name='list' />
      Library
    </Menu.Item>
    <Menu.Item as='a'>
      <Icon name='users' />
      Users
    </Menu.Item>
    <Menu.Item as='a'>
      <Icon name='money' />
      Finance
    </Menu.Item>
  </div>
);

const clientList = (
  <div>
    <Menu.Item as='a'>
      <Icon name='address book outline' />
      Personal
    </Menu.Item>
    <Menu.Item as='a'>
      <Icon name='file alternate' />
      Contract
    </Menu.Item>
    <Menu.Item as='a'>
      <Icon name='object group outline' />
      Survey
    </Menu.Item>
    <Menu.Item as='a'>
      <Icon name='file alternate outline' />
      MOM
    </Menu.Item>
    <Menu.Item as='a'>
      <Icon name='user secret' />
      CCI
    </Menu.Item>
    <Menu.Item as='a'>
      <Icon name='paper plane outline' />
      Letters
    </Menu.Item>
  </div>
);

function designList(match) {
  return (
    <div>
      <Menu.Item as={NavLink} to={`sketch2d`}>
        <Icon name='object group outline' size='large' />
        2D Sketch
      </Menu.Item>
      <Menu.Item as={NavLink} to={`layout`}>
        <Icon name='sticky note outline' size='large' />
        Layout
      </Menu.Item>
      <Menu.Item as={NavLink} to={`sketch3d`}>
        <Icon name='object group outline' size='large' />
        3D Sketch
      </Menu.Item>
      <Menu.Item as={NavLink} to={`visual`}>
        <Icon name='image outline' size='large' />
        Visual
      </Menu.Item>
      <Menu.Item as={NavLink} to={`presentation`}>
        <Icon name='tv' size='large' />
        Presentation
      </Menu.Item>
      <Menu.Item as={NavLink} to={`material`}>
        <Icon name='table' size='large' />
        Materials
      </Menu.Item>
      <Menu.Item as={NavLink} to={`selection`}>
        <Icon name='camera' size='large' />
        Selection
      </Menu.Item>
      <Menu.Item as='a'>
        <Icon name='unordered list' size='large' />
        RDS
      </Menu.Item>
      <Menu.Item as='a'>
        <Icon name='file alternate outline' size='large' />
        MOM
      </Menu.Item>
      <Menu.Item as='a'>
        <Icon name='users' size='large' />
        Team
      </Menu.Item>
    </div>
  );
}

const developmentList = (
  <div>
    <Menu.Item as='a'>
      <Icon name='object group outline' />
      Drawings
    </Menu.Item>
    <Menu.Item as='a'>
      <Icon name='table' />
      Room Schedule
    </Menu.Item>
    <Menu.Item as='a'>
      <Icon name='camera' />
      Progress Report
    </Menu.Item>
    <Menu.Item as='a'>
      <Icon name='users' />
      Team
    </Menu.Item>
  </div>
);

const constructionList = (
  <div>
    <Menu.Item as='a'>
      <Icon name='object group outline' />
      Drawings
    </Menu.Item>
    <Menu.Item as='a'>
      <Icon name='tasks' />
      Specs
    </Menu.Item>
    <Menu.Item as='a'>
      <Icon name='th list' />
      RFI
    </Menu.Item>
    <Menu.Item as='a'>
      <Icon name='table' />
      Submittals
    </Menu.Item>
    <Menu.Item as='a'>
      <Icon name='camera' />
      Field Report
    </Menu.Item>
    <Menu.Item as='a'>
      <Icon name='users' />
      Team
    </Menu.Item>
  </div>
);

function spaceSidebar(match) {
  return [
    {
      title: {
        content: "Client",
        icon: "user secret",
      },
      content: { content: clientList, key: "sa1-content" },
      key: "sub-accordion-1",
    },
    {
      title: {
        content: "Design",
        icon: "pencil alternate",
      },
      content: { content: designList(match), key: "sa2-content" },
      key: "sub-accordion-2",
    },
    {
      title: {
        content: "Development",
        icon: "cog",
      },
      content: { content: developmentList, key: "sa3-content" },
      key: "sub-accordion-3",
    },
    {
      title: {
        content: "Tender",
        icon: "file alternate outline",
      },
      content: { content: developmentList, key: "sa4-content" },
      key: "sub-accordion-4",
    },
    {
      title: {
        content: "Construction",
        icon: "industry",
      },
      content: { content: constructionList, key: "sa5-content" },
      key: "sub-accordion-5",
    },
    {
      title: {
        content: "Handover",
        icon: "handshake",
      },
      content: { content: developmentList, key: "sa6-content" },
      key: "sub-accordion-6",
    },
  ];
}

export default function SideBar(props) {
  const match = useRouteMatch();

  return (
    <>
      {props.visible && (
        <Menu
          borderless
          vertical
          inverted
          style={{
            position: "fixed",
            // width: "12rem",

            boxShadow: "5px 0px 10px rgba(5, 5, 5, .5)",
            backdropFilter: "blur(30px)",
            WebkitBackdropFilter: "blur(10px)",
            top: "30px",
            bottom: "0px",
            marginBottom: "0",
            zIndex: "90",
            overflowY: "auto",
            background: "#1F202490",
          }}
        >
          <Route path='/workspaces/:workspaceId' exact>
            <Menu.Item as={Link} to={`${match.url}/spaces`}>
              <Icon name='list' />
              Projects
            </Menu.Item>
            <Menu.Item as='a'>
              <Icon name='book' />
              Library
            </Menu.Item>
            <Menu.Item as={Link} to={`${match.url}/users`}>
              <Icon name='users' />
              Users
            </Menu.Item>
            <Menu.Item as='a'>
              <Icon name='money' />
              Finance
            </Menu.Item>
          </Route>
          <Route path='/workspaces/:workspaceId/spaces/:spaceId'>
            <Accordion
              inverted
              defaultActiveIndex={1}
              panels={spaceSidebar(match)}
            />
          </Route>

          <Menu.Item as={Link} to='/workspaces'>
            <Icon name='building outline' />
            workspaces
          </Menu.Item>
        </Menu>
      )}
      <Segment
        onClick={() => {
          //close sidebar
          props.setVisible(false);
        }}
        basic
        style={{
          position: "relative",
          marginTop: "0",

          // marginLeft: props.visible ? "200px" : "0px",
        }}
      >
        {props.children}
      </Segment>
    </>
  );
}
