import React from "react";

import { Grid } from "semantic-ui-react";
import CardDashboard from "./CardDashboard";

export default function CardColumn({ children, sections, collection }) {
  return (
    <>
      {sections.map((section) => (
        <Grid.Column
          key={section.id}
          //   mobile={16}
          style={{
            backgroundColor: "#494949",

            //   margin: " 7rem 0 0 0",

            position: "relative",
            width: "100%",
            height: "calc(100vh - 9rem - calc(100vh - 100%))",
            scrollSnapAlign: "start",
            borderRadius: "1rem",
            borderStyle: "solid",
            borderColor: "orange",
            borderWidth: "2px",
            overflow: "hidden",
          }}
        >
          <CardDashboard collection={collection} section={section}>
            {children}
          </CardDashboard>
        </Grid.Column>
      ))}
    </>
  );
}
