import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty, isLoaded, useFirestoreConnect } from "react-redux-firebase";
import { useParams } from "react-router";

import {
  Button,
  Card,
  Checkbox,
  Container,
  Dropdown,
  Header,
  Menu,
  Segment,
} from "semantic-ui-react";
import { openModal } from "../../app/common/modals/modalReducer";
import {
  cancelDocById,
  deleteDocFromFirestore,
} from "../../app/firestore/firestoreService";

export default function CardDashboard(props) {
  const { spaceId, supplierId, workspaceId } = useParams();

  //use workspaceId instead of spaceId in query if this dashboard was running at workspace level for library as example.
  const [cancelView, setCancelView] = useState(false);
  const dispatch = useDispatch();
  const [isCardEntered, setCardEntered] = useState(false);

  const isSelections = props.collection === "selections";

  const auth = useSelector((state) => state.firebase.auth);
  let cardQuery;
  if (isLoaded(auth) && !isEmpty(auth)) {
    cardQuery = {
      collection: isSelections ? "products" : props.collection,
      orderBy: ["createdAt", "desc"],
      storeAs: `${props.collection}/${spaceId ? spaceId : workspaceId}/${
        props.section.id
      }`,
      where: [
        isSelections
          ? ["sectionIds", "array-contains", props.section.id]
          : ["sectionId", "==", props.section.id],
        supplierId ? ["supplierId", "==", supplierId || null] : null,
        isSelections
          ? null
          : spaceId
          ? ["spaceId", "==", spaceId || null]
          : null,
        ["workspaceId", "==", workspaceId],
        ["isCancelled", "==", cancelView],
      ].filter(Boolean),
    };
  }

  useFirestoreConnect(() => [cardQuery]);

  const cards = useSelector(
    (state) =>
      state.firestore.ordered[
        `${props.collection}/${spaceId ? spaceId : workspaceId}/${
          props.section.id
        }`
      ]
  );

  const childrenWithProps = React.Children.map(props.children, (child) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, { cards });
    }
    return child;
  });

  // console.log("CARDs:", cards);

  return (
    <>
      <Segment
        basic
        style={{
          width: "100%",
          paddingLeft: "0",
          paddingRight: "0",
          paddingTop: "0",
          paddingBottom: "0",
          // boxShadow: "0 0 5px 10px #494949",
          boxShadow: "0 0 5px 10px rgba(73, 73, 73, .5)",
          // backdropFilter: "blur(30px)",
          zIndex: "100",
        }}
        onMouseEnter={() => {
          setCardEntered(true);
        }}
        onMouseLeave={() => {
          setCardEntered(false);
        }}
      >
        <Menu fluid widths={4} inverted borderless>
          <Menu.Item>
            <Button
              circular
              icon='plus'
              inverted
              color='orange'
              onClick={() => {
                dispatch(
                  openModal({
                    modalType: `${
                      props.collection === "suppliers" ? "Supplier" : "Card"
                    }FormModal`,
                    modalProps: {
                      workspaceId,
                      spaceId: spaceId || null,
                      supplierId: supplierId || null,
                      sectionId: props.section.id,
                      selectedDoc: null,
                      collection: props.collection,
                    },
                  })
                );
              }}
            />
          </Menu.Item>
          <Menu.Item>
            <Header
              textAlign='center'
              content={props.section.title}
              style={{ color: "#e2e2e2" }}
            />
          </Menu.Item>
          <Menu.Item>
            <Checkbox
              toggle
              onChange={() => {
                setCancelView(!cancelView);
              }}
            />
          </Menu.Item>
          <Menu.Item position='left'>
            {isCardEntered ? (
              <Dropdown
                // className='left floated'
                style={{ color: "white" }}
                icon='ellipsis vertical'
              >
                <Dropdown.Menu>
                  {props.section.isCancelled ? (
                    <>
                      <Dropdown.Item
                        // as={Link}
                        // to={`/`}
                        text='Restore'
                        icon='unhide'
                        onClick={() => {
                          dispatch(
                            cancelDocById(props.section.id, "sections", false)
                          );
                        }}
                      />
                    </>
                  ) : (
                    <Dropdown.Item
                      // as={Link}
                      // to={`/`}
                      text='Cancel'
                      icon='hide'
                      onClick={() => {
                        dispatch(
                          cancelDocById(props.section.id, "sections", true)
                        );
                      }}
                    />
                  )}

                  <Dropdown.Item
                    text='Edit'
                    icon='settings'
                    onClick={() => {
                      dispatch(
                        openModal({
                          modalType: "SectionFormModal",
                          modalProps: {
                            workspaceId: props.section.workspaceId,
                            spaceId: props.section.spaceId,
                            selectedDoc: props.section,
                            collection: props.collection,
                          },
                        })
                      );
                    }}
                  />
                </Dropdown.Menu>
              </Dropdown>
            ) : null}
          </Menu.Item>
        </Menu>
      </Segment>

      <Card.Group
        centered
        itemsPerRow={1}
        style={{
          // borderRadius: "1rem",
          // backgroundColor: "gray",
          height: "calc(100vh - 2rem - calc(100vh - 100%))",
          overflowY: "auto",
        }}
      >
        <Container
          style={{
            width: "100%",
            marginTop: "1rem",
            marginBottom: "5rem",
            // paddingTop: "7rem",
            // height: "calc(100vh - 7rem)",
          }}
        >
          {isLoaded(cards) && !isEmpty(cards) ? (
            <>{childrenWithProps}</>
          ) : (
            // <></>
            <>'loading...'</>
          )}
        </Container>
      </Card.Group>
    </>
  );
}
