import React from "react";
import { Card, Comment, Header, Image, Item } from "semantic-ui-react";
import ModalWrapper from "../../app/common/modals/ModalWrapper";
import CommentForm from "../comment/CommentForm";
import CommentList from "../comment/CommentList";

export default function CardModal({ data, collectionFolder }) {
  return (
    <ModalWrapper size='small'>
      <Item.Group>
        <Item>
          <Image
            as='img'
            loading='lazy'
            rounded
            src={data.imageResizedLargeUrl}
            style={{
              inlineSize: "100%",
              aspectRatio: "16 / 9",
              objectFit: "cover",
            }}
          />
        </Item>
        <Item>
          <Item.Content>
            <Item.Meta>{data.title}</Item.Meta>
            <Item.Description></Item.Description>
            <Item.Extra>{data.description}</Item.Extra>
          </Item.Content>
        </Item>
        {data.company && (
          <>
            <Item>
              <Item.Content>
                <Item.Extra>Company Name</Item.Extra>
                <Item.Meta>{data.company}</Item.Meta>
              </Item.Content>
              <Item.Content>
                <Item.Extra>Address</Item.Extra>
                <Item.Meta>{data.address}</Item.Meta>
              </Item.Content>
              <Item.Content>
                <Item.Extra>Email</Item.Extra>
                <Item.Meta>{data.email}</Item.Meta>
              </Item.Content>
            </Item>
            <Item>
              <Item.Content>
                <Item.Extra>Telephone</Item.Extra>
                <Item.Meta>{data.telephone}</Item.Meta>
              </Item.Content>
              <Item.Content>
                <Item.Extra>Mobile</Item.Extra>
                <Item.Meta>{data.mobile}</Item.Meta>
              </Item.Content>
              <Item.Content>
                <Item.Extra>Agent</Item.Extra>
                <Item.Meta>{data.agent}</Item.Meta>
              </Item.Content>
              <Item.Content>
                <Item.Extra>Website</Item.Extra>
                <Item.Meta>{data.website}</Item.Meta>
              </Item.Content>
            </Item>
          </>
        )}
      </Item.Group>
      <CommentForm
        docId={data.id}
        workspaceId={data.workspaceId}
        spaceId={data.spaceId}
        collectionFolder={collectionFolder}
      />
      <CommentList docId={data.id} />
    </ModalWrapper>
  );
}
