import React from "react";
import { useSelector } from "react-redux";
import { isEmpty, isLoaded, useFirestoreConnect } from "react-redux-firebase";
import { useParams } from "react-router";
import { Header } from "semantic-ui-react";

export default function WorkspaceDetailPage() {
  const { workspaceId } = useParams();
  const selectedWorkSpace = useSelector(
    (state) => state.firestore.ordered[`workspaces/${workspaceId}`]
  );

  // useFirestoreConnect loaded in navbar
  // const SelectedWorkspaceQuery = {
  //   collection: "workspaces",
  //   doc: workspaceId,
  //   storeAs: `workspaces/${workspaceId}`,
  // };

  // useFirestoreConnect(() => [SelectedWorkspaceQuery]);

  return (
    <>
      {isLoaded(selectedWorkSpace) && !isEmpty(selectedWorkSpace) ? (
        <>
          <Header content={`Workspace:  ${selectedWorkSpace[0].title}`} />
        </>
      ) : (
        <></>
      )}
    </>
  );
}
