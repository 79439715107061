import { combineReducers } from "redux";
import { firebaseReducer } from "react-redux-firebase";
import { firestoreReducer } from "redux-firestore";

import ageReducer from "./ageReducer";
import modalReducer from "../../common/modals/modalReducer";
import authReducer from "../../../features/auth/authReducer";
import testReducer from "../../../features/sandbox/testReducer";
import asyncReducer from "../../async/asyncReducer";
import workspaceReducer from "../../../features/workspace/workspaceReducer";
import spaceReducer from "../../../features/space/spaceReducer";
import userReducer from "../../../features/user/userReducer";
// import taskReducer from './taskReducer'

// Add firebase to reducers
const rootReducer = combineReducers({
  firebase: firebaseReducer,
  firestore: firestoreReducer,
  age: ageReducer,
  // tasks:taskReducer
  test: testReducer,
  modals: modalReducer,
  auth: authReducer,
  async: asyncReducer,
  workspace: workspaceReducer,
  space: spaceReducer,
  user: userReducer,
});

export default rootReducer;
