import { format, formatDistance } from "date-fns";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  Button,
  Card,
  Divider,
  Dropdown,
  Grid,
  Header,
  Icon,
  Image,
  Label,
  List,
  Popup,
  Segment,
} from "semantic-ui-react";
import { openModal } from "../../app/common/modals/modalReducer";
import { saveAs } from "file-saver";
import {
  cancelDocById,
  deleteDocFromFirestore,
} from "../../app/firestore/firestoreService";

export default function CardListItem({
  card,
  collection,
  children,
  direction,
  extra = false,
  compact = false,
}) {
  let descExtra = extra ? 200 : 80;

  const dispatch = useDispatch();
  const [isCardEntered, setCardEntered] = useState(false);
  //   const [isButtonEntered, setButtonEntered] = useState(false);

  const selectedWorkspace = useSelector(
    (state) =>
      card.workspaceId &&
      state.firestore.ordered[`workspaces/${card.workspaceId}`]
  );
  function getMember() {
    return selectedWorkspace[0].members.filter(
      (member) => member.id === card.createdBy
    )[0];
  }
  return (
    <>
      <Card
        className={direction}
        fluid
        onMouseEnter={() => {
          setCardEntered(true);
        }}
        onMouseLeave={() => {
          setCardEntered(false);
        }}
      >
        {direction === "vertical" && (
          <>
            {card.imageResizedSmallUrl ? (
              <div className='img-ratio'>
                <Image
                  size='medium'
                  src={card.imageResizedSmallUrl}
                  //   wrapped
                  ui={false}
                  onClick={() => {
                    dispatch(
                      openModal({
                        modalType: "CardModal",
                        modalProps: {
                          data: card,
                          collectionFolder: collection,
                        },
                      })
                    );
                  }}
                />
              </div>
            ) : compact ? (
              <></>
            ) : (
              <div className='img-ratio'>
                <Image />
              </div>
            )}
          </>
        )}

        <Card.Content style={{ overflow: "hidden" }}>
          <Card.Header
            className='left floated'
            content={`${extra ? card.code + " : " : ""} ${card.title}`}
          />

          {isCardEntered ? (
            <Dropdown
              direction='left'
              className='right floated'
              style={{ color: "black" }}
              icon='ellipsis vertical'
            >
              <Dropdown.Menu>
                {card.isCancelled ? (
                  <>
                    <Dropdown.Item
                      // as={Link}
                      // to={`/`}
                      text='Delete'
                      icon='trash'
                      onClick={() => {
                        dispatch(deleteDocFromFirestore(card, collection));
                      }}
                    />
                    <Dropdown.Item
                      // as={Link}
                      // to={`/`}
                      text='Restore'
                      icon='unhide'
                      onClick={() => {
                        dispatch(cancelDocById(card.id, collection, false));
                      }}
                    />
                  </>
                ) : (
                  <Dropdown.Item
                    // as={Link}
                    // to={`/`}
                    text='Cancel'
                    icon='hide'
                    onClick={() => {
                      dispatch(cancelDocById(card.id, collection, true));
                    }}
                  />
                )}

                <Dropdown.Item
                  text='Edit'
                  icon='settings'
                  onClick={() => {
                    dispatch(
                      openModal({
                        modalType: `${
                          collection === "suppliers" ? "Supplier" : "Card"
                        }FormModal`,
                        modalProps: {
                          workspaceId: card.workspaceId,
                          spaceId: card.spaceId,
                          selectedDoc: card,
                          collection: collection,
                        },
                      })
                    );
                  }}
                />
              </Dropdown.Menu>
            </Dropdown>
          ) : null}
          {extra && <Card.Description content={card.type} />}

          <Card.Description
            content={`${card.description.substring(0, descExtra)}${
              card.description.length > descExtra ? "..." : ""
            }`}
          />
          {children[1]}
          <Divider />

          <Card.Meta className='left floated'>
            <List horizontal>
              <List.Item>
                {/* <List.Content content={getMember().displayName} /> */}
                <Popup
                  basic
                  content={getMember().displayName}
                  trigger={
                    <Image
                      size='huge'
                      //   floated='right'
                      avatar
                      // style={{ top: "calc(100% - 2em)", border: "1px solid white" }}
                      style={{ border: "2px solid orange" }}
                      // spaced='right'
                      src={getMember().photoURL || "/assets/user.png"}
                    />
                  }
                />
              </List.Item>
              <List.Item>
                <List.Content>
                  {card.createdAt && (
                    <div>{format(card.createdAt.toDate(), "MMMM d, yyyy")}</div>
                  )}
                </List.Content>
              </List.Item>
            </List>
          </Card.Meta>
          <Segment basic floated='right' style={{ margin: "0", padding: "0" }}>
            <div style={{ display: "inline-flex", gap: "0.5rem" }}>
              {children[0] || children}
            </div>
          </Segment>
        </Card.Content>
        {card.imageResizedSmallUrl ? (
          direction === "horizontal" && (
            <Image
              size='medium'
              src={card.imageResizedSmallUrl}
              wrapped
              ui={false}
            />
          )
        ) : (
          <></>
        )}
      </Card>
    </>
  );
}
