import React from "react";
import { Item, Segment } from "semantic-ui-react";

export default function UserListItem({ user, adminId }) {
  return (
    <Segment.Group>
      <Segment>
        <Item.Group>
          <Item>
            <Item.Image
              size='tiny'
              circular
              src={user.photoURL || "/assets/user.png"}
            />
            <Item.Content>
              <Item.Header content={user.displayName} />
              {user.id === adminId ? <Item.Extra content='Admin' /> : null}
            </Item.Content>
          </Item>
        </Item.Group>
      </Segment>
    </Segment.Group>
  );
}
