import { winToDpx, winToLinux } from "../common/util/util";

export function shareLinkDbx(dbxPath, valueId) {
  return (dispatch, getState, getFirebase) => {
    const firebase = getFirebase();
    const firestore = firebase.firestore();

    let config;

    config = {
      headers: {
        dbxPath: winToDpx(dbxPath),
      },
    };

    return fetch("https://srv1.zadreality.com/sharelink", config)
      .then((response) => {
        return response.json().then((data) => {
          if (data.status >= 400) {
            throw data;
          } else {
            firestore
              .collection("comms")
              .doc(valueId)
              .update({
                dbxLinkUrl: data.dbxShareURL || null,
                linuxServerPath: winToLinux(dbxPath),
              });
            return data;
          }
        });
      })
      .catch((error) => {
        let errorObj;

        if (error.message) {
          errorObj = {
            error: { error_summary: error.message },
          };
          throw errorObj;
        } else if (error.status === 400) {
          errorObj = {
            error: { error_summary: error.error },
          };
          throw errorObj;
        } else {
          throw error;
        }
      });
  };
}
//shareLink made for testing only ---------
export async function shareLink(dbxPath) {
  let config = {
    headers: {
      dbxPath: dbxPath,
    },
  };
  try {
    const result = await fetch("https://srv1.zadreality.com/sharelink", config);
    return result
      .json()
      .then((data) => {
        if (data.status >= 400) {
          console.log(" error 1", data);
          throw data;
        } else {
          console.log("no error", data);
          return data;
        }
      })
      .then((error) => {
        console.log("there is error 2", error.message, "errrr");
      });
  } catch (error) {
    console.log("there is error", error.message, "errrr");
    throw error;
  }
}
