import { Form, Formik } from "formik";
import React from "react";
import { Button, Header, Label, Segment } from "semantic-ui-react";
import MyTextInput from "../../app/common/form/MyTextInput";
import * as Yup from "yup";
import MyTextArea from "../../app/common/form/MyTextArea";

import { addComm } from "../../app/firestore/firestoreService";
import { useDispatch } from "react-redux";

const initialValues = {
  winServerPath: "",
  linuxServerPath: "",
  dbxPath: "",
  dbxLinkUrl: "",
  notes: "",
  date: "",
};

const validationSchema = Yup.object({
  winServerPath: Yup.string()
    .required("you must provide a path")
    .matches(
      /^([P]:\\)(?:projects+)(\\[a-z._\-\s0-9]+\\)(?:communication+)(\\[a-z_\-\s0-9]+\\)(?:issued+)(\\)(\d{8})(\\)(?:batch_+\d{3})$/gi,
      "you must add correct windows server path"
    ),
  //notice the /gi at the end to ignore the letter case
  notes: Yup.string().required("you must provide notes"),
});

export default function CommForm({ setPointer }) {
  const dispatch = useDispatch();
  return (
    <Segment clearing>
      <Header sub color='teal' content='Create Communication Link' />
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting, setErrors }) => {
          dispatch(addComm(values))
            .then(() => {
              setSubmitting(false);
              setPointer();
            })
            .catch((error) => {
              setErrors({ submit: error.message });
              setSubmitting(false);
            });
        }}
      >
        {({ isSubmitting, isValid, dirty, errors }) => (
          <Form className='ui form'>
            <MyTextInput name='winServerPath' placeholder='P:\ drive path' />
            <MyTextArea
              name='notes'
              placeholder='Add notes to meake it searchable...'
              rows={3}
            />

            {errors.submit && (
              <Label
                basic
                color='red'
                style={{ marginBottom: 10 }}
                content={errors.submit}
              />
            )}
            <Button
              loading={isSubmitting}
              disabled={!isValid || !dirty || isSubmitting}
              type='submit'
              floated='right'
              positive
              content='Submit'
            />
          </Form>
        )}
      </Formik>
    </Segment>
  );
}
