import React from "react";
import ModalWrapper from "../../app/common/modals/ModalWrapper";
import CommitFileForm from "./CommitFileForm";

export default function CommitFileFormModal({ data, field, collection }) {
  console.log("modal: ", data.id, data.spaceId, data.workspaceId);
  return (
    <ModalWrapper size='small'>
      <CommitFileForm
        docId={data.id}
        spaceId={data.spaceId}
        workspaceId={data.workspaceId}
        field={field}
        collection={collection}
      />
    </ModalWrapper>
  );
}
