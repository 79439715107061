import React from "react";
import CommListItem from "./CommListItem";

export default function WorkspaceList({ comms }) {
  return (
    <>
      {comms.map((comm) => (
        <CommListItem comm={comm} key={comm.id} />
      ))}
    </>
  );
}
