import React from "react";
import { Container } from "semantic-ui-react";
import MaterialListItem from "./MaterialListItem";

export default function MaterialList({ cards }) {
  return (
    <Container>
      {cards.map((card) => (
        <MaterialListItem card={card} key={card.id} />
      ))}
    </Container>
  );
}
