const initState = {
    age:20,
    loading:false
}

const ageReducer =(state = initState, action)=>{
    const newState = {...state}

    switch (action.type){
        case 'AGE_UP':
            newState.age += action.value
            // newState.loading = false
            break

        case 'AGE_DOWN':
            newState.age -= action.value
            // newState.loading = false
            break
        
        case 'LOADING_START':
            newState.loading = true
            break
        case 'LOADING_FINISH':
            newState.loading = false
            break
        default:
            return newState
        
    }
    return newState

}

export default ageReducer