import rootReducer from "./reducers/rootReducer";

import { createStore, applyMiddleware } from "redux";
import { getFirebase } from "react-redux-firebase";

import thunk from "redux-thunk";

import { composeWithDevTools } from "redux-devtools-extension";
import { loadWorkspaces } from "../../features/workspace/workspaceActions";
import { verifyAuth } from "../../features/auth/authActions";

const middlewares = [thunk.withExtraArgument(getFirebase)];

const initialState = {};
const store = createStore(
  rootReducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middlewares))
);
store.dispatch(verifyAuth());
//to be deleted from here for test only
store.dispatch(loadWorkspaces());
// end of delete

export default store;
