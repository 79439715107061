import React from "react";
import { useSelector } from "react-redux";
import { isEmpty, isLoaded, useFirestoreConnect } from "react-redux-firebase";
import { useParams } from "react-router";
import { Header } from "semantic-ui-react";

export default function ProfilePage() {
  const { id } = useParams();
  const { profile } = useSelector((state) => state.firebase);
  const selectedUser = useSelector(
    (state) => state.firestore.ordered[`users/${id}`]
  );

  const SelectedProfileQuery = {
    collection: "users",
    doc: id,
    storeAs: `users/${id}`,
  };

  useFirestoreConnect(() => [SelectedProfileQuery]);

  return (
    <>
      <Header content={`  ${profile.isLoaded}`} />
      <Header content={`Profile Page of:  ${profile.displayName}`} />
      {isLoaded(selectedUser) && !isEmpty(selectedUser) ? (
        <Header
          content={`selected Profile Page of:  ${selectedUser[0].displayName}`}
        />
      ) : (
        <></>
      )}
    </>
  );
}
