import React from "react";
import { Button, Item, Segment } from "semantic-ui-react";
import { format, fromUnixTime } from "date-fns";
import { useDispatch } from "react-redux";
// import { cancelComm } from "../../app/firestore/firestoreService";
import { shareLinkDbx } from "../../app/gCloud/gCloudService";
import { cancelComm } from "../../app/firestore/firestoreService";
import { toast } from "react-toastify";

export default function CommListItem({ comm }) {
  const dispatch = useDispatch();
  return (
    <Segment.Group>
      <Segment>
        <Item.Group>
          <Item>
            <Item.Content>
              <Item.Content
                content={comm.winServerPath}
                style={{
                  wordBreak: "break-all",
                }}
              />
              <Item.Extra
                content={comm.dbxLinkUrl}
                style={{
                  wordBreak: "break-all",
                }}
              />
              <Item.Description
                content={comm.notes}
                style={{
                  wordBreak: "break-all",
                }}
              />
            </Item.Content>
          </Item>
        </Item.Group>
      </Segment>
      <Segment clearing>
        {comm.createdAt?.seconds
          ? format(fromUnixTime(comm.createdAt.seconds), "MMMM d, yyyy")
          : "loading ..."}
        <Button
          onClick={() => {
            dispatch(cancelComm(comm.id));
          }}
          icon='trash alternate'
          color='black'
          floated='right'
          size='tiny'
        />

        <Button
          content='Generate Link'
          color='teal'
          size='tiny'
          floated='right'
          icon='linkify'
          onClick={() => {
            dispatch(shareLinkDbx(comm.winServerPath, comm.id))
              .then((res) => {
                toast.success("Dropbox link generated");
                console.log("response catched", res);
              })
              .catch((error) => {
                toast.error(error?.error?.error_summary);
                console.log("error catched!", error?.error?.error_summary);
              });
          }}
        />
      </Segment>
    </Segment.Group>
  );
}
