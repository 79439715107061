import { Form, Formik } from "formik";
import React from "react";
import { Button, Header, Label, Segment } from "semantic-ui-react";
import MyTextInput from "../../app/common/form/MyTextInput";
import * as Yup from "yup";
import { addCard, updateCard } from "../../app/firestore/firestoreService";
import { useDispatch } from "react-redux";
import { closeModal } from "../../app/common/modals/modalReducer";

const initialValues = {
  title: "",
};

const validationSchema = Yup.object({
  title: Yup.string().required("you must provide a title"),
});

export default function SectionForm({
  spaceId,
  supplierId,
  workspaceId,
  selectedDoc,
  collection,
}) {
  const dispatch = useDispatch();
  console.log("spaceId,workspaceId2", spaceId, workspaceId);
  return (
    <Segment clearing basic>
      <Header sub content={selectedDoc ? "Edit..." : "Add..."} />
      <Formik
        enableReinitialize
        initialValues={selectedDoc ? selectedDoc : initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values, { setSubmitting, setErrors }) => {
          try {
            if (selectedDoc) {
              await dispatch(updateCard(values, "sections", selectedDoc.id));
            } else {
              await dispatch(
                addCard(
                  values,
                  "sections",
                  spaceId,
                  supplierId,
                  workspaceId,
                  collection
                )
              );
            }

            dispatch(closeModal());
            setSubmitting(false);
          } catch (error) {
            setErrors({ submit: error.message });
            setSubmitting(false);
          }
        }}
      >
        {({ isSubmitting, isValid, dirty, errors }) => (
          <Form className='ui form'>
            <MyTextInput name='title' placeholder='Section name' />
            {errors.submit && (
              <Label
                basic
                color='red'
                style={{ marginBottom: 10 }}
                content={errors.submit}
              />
            )}
            <Button
              style={{ backgroundColor: "#ffa600", color: "white" }}
              loading={isSubmitting}
              disabled={!isValid || !dirty || isSubmitting}
              type='submit'
              floated='right'
              positive
              content={selectedDoc ? "Edit" : "Add"}
            />
          </Form>
        )}
      </Formik>
    </Segment>
  );
}
