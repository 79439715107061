import React from "react";
import { Comment, Image, Item, Segment } from "semantic-ui-react";
import { formatDistance } from "date-fns";

export default function CommentListItem({ comment }) {
  return (
    <Comment>
      <Comment.Avatar src={comment.photoURL || "/assets/user.png"} />
      <Comment.Content>
        <Comment.Author as='a'>{comment.displayName}</Comment.Author>
        <Comment.Metadata>
          {comment.createdAt && (
            <div>{formatDistance(comment.createdAt.toDate(), new Date())}</div>
          )}
        </Comment.Metadata>
        <Comment.Text>{comment.comment}</Comment.Text>
        <Image
          as='img'
          loading='lazy'
          rounded
          style={{ width: "100%" }}
          src={comment.imageResizedLargeUrl || null}
        />

        <Comment.Actions>
          <Comment.Action>Reply</Comment.Action>
        </Comment.Actions>
      </Comment.Content>
    </Comment>
  );
}
