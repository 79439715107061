import React from "react";
import { useSelector } from "react-redux";
import TestModal from "../../../features/sandbox/TestModal";
import LoginForm from "../../../features/auth/LoginForm";
import RegisterForm from "../../../features/auth/RegisterForm";

import CardFormModal from "../../../features/card/CardFormModal";
import SectionFormModal from "../../../features/section/SectionFormModal";
import CardModal from "../../../features/card/CardModal";
import CommitFileFormModal from "../../../features/commit/CommitFileFormModal";

import CommentModal from "../../../features/comment/CommentModal";
import SpaceModal from "../../../features/space/SpaceModal";
import UserFormModal from "../../../features/user/UserFormModal";
import WorkspaceFormModal from "../../../features/workspace/WorkspaceFormModal";

import SupplierFormModal from "../../../features/library/supplier/SupplierFormModal";

export default function ModalManager() {
  const modalLookup = {
    TestModal,
    CardModal,
    CardFormModal,
    SupplierFormModal,
    SectionFormModal,
    CommitFileFormModal,
    CommentModal,
    SpaceModal,
    UserFormModal,
    WorkspaceFormModal,
    LoginForm,
    RegisterForm,
  };
  const currentModal = useSelector((state) => state.modals);
  let renderedModal;

  if (currentModal) {
    const { modalType, modalProps } = currentModal;
    const ModalComponent = modalLookup[modalType];
    renderedModal = <ModalComponent {...modalProps} />;
  }

  return <span>{renderedModal}</span>;
}
